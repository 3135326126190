
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Loader } from 'semantic-ui-react';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { v4 as uuidv4 } from 'uuid';
import { theme } from '../components/theme';
import { getAllGroups } from "../features/groups/groupSlice";
import { postApplication } from "../features/survey/surveySlice";
import { Applicationform } from '../surveys/ApplicationFrom';

function Application() {
    const dispatch = useDispatch();
    const { code } = useParams(); // code as in application code, indicates the workshop group the applicant is applying to

    const { groupsArr, isLoading } = useSelector((state) => state.groups);

    const group = groupsArr?.find((group) => group.appcode === code);

    useEffect(() => {
        dispatch(getAllGroups());
    }, [dispatch]);
    
    function getCurrentDateTime() {
        const now = new Date();
        const date = now.toUTCString();
        
        return date; // Space between date and time, 'Z' at the end for UTC
    }

    const survey = new Model(Applicationform);
    
    survey.applyTheme(theme);
    
    if (!group) {
        survey.completedHtml = "<h3>There was an error loading the survey. Please try again later.</h3>";
        survey.doComplete(); //sets the survey as completed
    } else {
        survey.onComplete.add(async (sender, options) =>{
            const id = uuidv4();
            var data = {}
            data = {
                id: id,
                code: code,
                datetimeApplied: getCurrentDateTime(),
                name: sender.data.name || null,
                lastname: sender.data.lastname || null,
                phonenumber: sender.data.phonenumber || null,
                email: sender.data.email || null,
                instemail: sender.data.instemail || null,
                linkedin: sender.data.linkedin || null,
                country: sender.data.country === "other" ? sender.data['country-Comment'] : sender.data.country || null,
                edulevel: sender.data.edulevel || null,
                eduPosition: sender.data.eduPosition === "other" ? sender.data['eduPosition-Comment'] : sender.data.eduPosition || null,
                specialization: sender.data.specialization || null,
                academicInstitution: sender.data.academicInstitution || null,
                companyOrganization: sender.data.companyOrganization || null,
                patentIdea: sender.data.patentIdea || null,
                patentIdeaName: sender.data.patentIdeaName || null,
                patentIdeaStage: sender.data.patentIdeaStage === "other" ? sender.data['patentIdeaStage-Comment'] : sender.data.patentIdeaStage || null,
                receivingNotice: sender.data.receivingNotice || 0,
                recordingConsent: sender.data.recordingConsent ? sender.data.recordingConsent[0] : null,
                confidentialityConsent: sender.data.confidentialityConsent ? sender.data.confidentialityConsent[0] : null,
                collaborationConsent: sender.data.collaborationConsent || null,
                collaborationConsentComments: sender.data['collaborationConsent-Comment'] || null,
                commitmentConsent: sender.data.commitmentConsent ? sender.data.commitmentConsent[0] : null,
                commitmentConsentComments: sender.data['commitmentConsent-Comment'] || null,
                eligibilityConsent: sender.data.eligibilityConsent ? sender.data.eligibilityConsent[0] : null,
            }
            
            options.showSaveInProgress();
            try {
                const resultAction = await dispatch(postApplication(data)).unwrap();
                options.showSaveSuccess();
            } catch (error) {
                console.error(error);
                options.showSaveError();
            }
        });
    }

    return (
        <div>
            {isLoading ? (
                <Loader size="huge" active content="Loading survey..." />
            ) : (
                <Survey model={survey} />
            )}
        </div>
    )
}


export default Application;





