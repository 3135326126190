const logo = require('../assets/FL2F-logo.webp');

export const Followupform = {
    "showPreviewBeforeComplete": "showAnsweredQuestions",
    "title": "Follow up Workshop Survey",
    "description": "Thank you for your time and we look forward to hearing from you and catching up at the next alumni event!",
    "logo": logo,
    "logoWidth": "auto",
    "logoHeight":"80px",
    "logoFit":"contain",
    "logoPosition":"right",
    "pages" : [
        {
        "name": "page1",
        "elements": 
        [
            {
                "type":"panel",
                "name":"intro_panel",
                "elements":[
                    {
                    "type": "boolean",
                    "name": "FL2F_enhanced_thinking",
                    "title": "Do you feel FL2F has enhanced your thinking?",
                    "labelTrue": "Yes",
                    "labelFalse": "No"
                    },
                    {
                        "type": "text",
                        "name": "positive_results",
                        "title": "Do you have any positive results in your life which you attribute to participating in the workshop?",
                        "placeHolder": "Please explain"
                    },
                    {
                        "type": "text",
                        "name": "participants_successes",
                        "title": "Are there any successes you would like to share with us?",
                        "placeHolder": "Please explain"
                    },
                ]

            },
            {
                "type":"panel",
                "name":"ip_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "21_pursue_any_IP",
                        "title": "To what extend did you pursue or make progress with any intellectual property?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "purse_any_IP_comment",
                        "title": "Do you have any comments in regards to the question above?",
                        "description": "Patent applications, trademarks to tell us about.",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"grant_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "22_apply_for_gvmt_grant",
                        "title": "To what extent did you promote a government grant application regarding your project?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "gvmt_grant_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"ideas_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "promote_ideas",
                        "title": "To what extent did you promote or present your ideas?",
                        "description": "Did you have interesting meetings/pitches in your journey.",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "promote_ideas_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"collaborate_with_industry_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "collaborate_with_industry",
                        "title": "To what extent did you collaborate with industry?",
                        "description": "Did you have interesting meetings/pitches in your journey.",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "collaborate_with_industry_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "description": "Which type of grant/contract?",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"angel_vc_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "angel_vc_funding",
                        "title": "To what extent did you apply for angel/VC funding?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "angel_vc_funding_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "description": "Which entities were approached/have supported)?",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"secure_funding_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "23_have_success_in_securing_funding",
                        "title": "To what extent did you have success in securing funds?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "securing_funding_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "description": "Any advice/suggestions regarding funding entities based on your journey so far.",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"FL2F_participants_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "24_work_with_other_FL2F_participants",
                        "title": "To what extent did you work with other FL2F participants?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "FL2F_participants_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "description": "Collaborations with same cohort participants, or other FL2F alumni.",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"new_initiatives_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "25_start_new_initiatives",
                        "title": "To what extent did you start new initiatives?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "new_initiatives_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "description": "Not just related to your specific project, but any initiative you started in your department/faculty/community.",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },
            {
                "type":"panel",
                "name":"open_a_new_company_panel",
                "elements":[
                    {
                        "type": "matrix",
                        "name": "26_open_a_new_company",
                        "title": "To what extent did you pursue company incorporation?",
                        "isRequired": true,
                        "columns": [
                            {
                                "value": 1,
                                "text": "Did not make any progress"
                            }, {
                                "value": 2,
                                "text": "Planning to start soon"
                            }, {
                                "value": 3,
                                "text": "Started promotion"
                            }, {
                                "value": 4,
                                "text": "Made progress"
                            }, {
                                "value": 5,
                                "text": "Made significant progress"
                            }
                        ],
                        "rows": [
                            {
                                "value": "answer",
                                "text": "Please select"
                            }
                        ],
                    },
                    {
                        "type": "comment",
                        "name": "new_company_comment",
                        "title": "Do you have any comments to share in regards to the question above?",
                        "placeHolder": "Please explain",
                        "rows": 2,
                        "autoGrow": true,
                        "allowResize": false,
                        "maxLength": 500
                    },
                ]

            },

            
        ],
    },
    {
        "name": "page2",
        "elements": [
            {
                "type":"matrix",
                "name":"refliction_matrix",
                "title":"In retrospect, can you reflect on how helpful each learning category was for your personal (work) success?",
                "columns": [
                    {"value": 5,
                    "text": "Extremely helpful"
                    }, {
                        "value": 4,
                        "text": "Very helpful"
                    }, {
                        "value": 3,
                        "text": "Somewhat helpful"
                    }, {
                        "value": 2,
                        "text": "Not so helpful"
                    }, {
                        "value": 1,
                        "text": "Not at all helpful"
                    }
                ],
                "rows": [
                    {
                        "value": "Intellectual_property",
                        "text": "Intellectual property"
                    }, {
                        "value": "Implicit_bias",
                        "text": "Implicit bias"
                    }, {
                        "value": "Creative_thinking",
                        "text": "Creative thinking"
                    }, {
                        "value": "Communication",
                        "text": "Communication skill development (presentations)"
                    }, {
                        "value": "Negotiation",
                        "text": "Negotiation skill development"
                    }, {
                        "value": "early_customer_development",
                        "text": "Early customer development"
                    }, {
                        "value": "Resilience",
                        "text": "Resilience development"
                    }, {
                        "value": "Vision_and_mission",
                        "text": "Vision and mission exercises"
                    }, {
                        "value": "Strategic_planning",
                        "text": "Strategic Planning exercises"
                    }, {
                        "value": "guest_talks",
                        "text": "Guest talks"
                    }
                ],
            },
            {
                "type":"panel",
                "name":"afterword_panel",
                "elements":[
                    {
                        "type": "rating",
                        "name": "recommend_workshop",
                        "title": "How likely is it that you would recommend the workshop to a friend or colleague?",
                        "isRequired": true,
                        "rateMin": 1,
                        "rateMax": 5,
                        "minRateDescription": "Not likely",
                        "maxRateDescription": "Extremely likely"
                    },
                    {
                        "type": "text",
                        "name": "impact_of_workshop",
                        "title": "Is there anything else you would like to share about the workshop impact on you?",
                        "placeHolder": "Please explain",
                    },
                    {
                        "type": "text",
                        "name": "programs_to_suggestions",
                        "title": "Are there any other programs you would suggest following up with, after completing FL2F, based on your journey?",
                        "placeHolder": "Please explain",
                    }
                ]
            }
            
        ]
    }
    ],
    "showQuestionNumbers": false,
};