import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import 'survey-core/defaultV2.min.css';
import Application from './pages/Application';
import Consent from './pages/Consent';
import Followup from './pages/Followup';
import Postworkshop from './pages/Postworkshop';
import Preworkshop from './pages/Preworkshop';

function App() {

  //Routes are defined here, login is the default route the rest are private routes that can only be accessed if the user is logged in
  return (
    <Router>
      <Routes>
        <Route path='/apply/:code' element={<Application/> } />
        <Route path="/prework/users/:id" element={<Preworkshop/>} />
        <Route path='/postwork/users/:id' element={<Postworkshop/>} />
        <Route path='/followup/users/:id' element={<Followup/>} />
        <Route path='/consent/users/:id' element={<Consent/>} />
        {/*Routes for the old followup forms, not private, depricated/}
        {/* <Route path='/prev/followup/users/:id' element={<OldFollowup/>} /> */} 
      </Routes>
    </Router>
  );
}

export default App;
