import { configureStore } from "@reduxjs/toolkit";
import groupReducer from "../features/groups/groupSlice";
import memberReducer from "../features/members/memberSlice";
import surveyReducer from "../features/survey/surveySlice";

export const store = configureStore({
  reducer: {

    members: memberReducer,
    groups: groupReducer,
    surveys: surveyReducer,
  },
});
