import axios from "axios";

let API_URL = "";
if (process.env.NODE_ENV !== "production") {
  API_URL = "http://localhost:5000/api/groups/"; // lets us use the backend server in local development
}
else {
  API_URL = "/api/groups/";
}

// GET all members
const getAllGroups = async () => {
  const { data } = await axios.get(API_URL);
  return data;
};




const memberService = { getAllGroups };

export default memberService;
